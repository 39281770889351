<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Perform departure control for driver</h4>
        <form action="#" @submit.prevent="handleSubmit">
          <div class="row">

              <div class="col-sm-6" v-for="control in controls">
                <div class="form-group">
                  <label>{{control.label}}</label>
                  <input type="text" class="form-control" v-model="control['response']" :required="control.required_for_arrival === 'yes'">
                  <span v-if="control.required_for_arrival === 'yes'" class="text-danger "> This field is required</span>
                  <span v-else class="text-black-50"> This field is optional</span>

                </div>

              </div>
              <div class="col-sm-12 mt-4">
              <vue-dropzone
                  id="departureControlImages"
                  ref="departureControlImages"
                  :use-custom-slot="true"
                  :options="dropzoneOptions"
              ><div class="dz-message needsclick">
                <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                <h3>No Departure control images uploaded</h3>
              </div>
              </vue-dropzone>

            </div>
              <div class="col-md-12 mt-4">
                <button type="submit" class="btn btn-success"><i class="fa fa-save"></i> Save</button>
              </div>

          </div>

        </form>

      </div>
    </div>

  </div>
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import {rentalsService} from "../../../../apis/rentals.service";

export default {

  data(){
    return {
      controls: [],
      rental: null,
      dropzoneOptions: {
        url: '#',
        maxFilesize: 0.5,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        addRemoveLinks: true,
        autoProcessQueue: false,
        maxFiles: 20,
      },
    }
  },
  components: {
    vueDropzone: vue2Dropzone,
  },
  computed:{
  },
  watch: {
    '$store.getters.GET_DEPARTURE_RENTAL_CONTROLS': function (c) {
      if(c){
        this.controls = [];
        c.forEach(e => {
          this.controls.push({...e})
        })
      }
    }
  },
  methods: {
    handleSubmit(){
      console.log(this.controls)

      const formData = new FormData();
      //'carId' => 'required',
      // 'driverUserId' => 'required',
      //     'rentalId' => 'required',
      //     'departureControls' => 'required|array'

      formData.append('carId', this.rental['car_id'])
      formData.append('rentalId', this.rental['id'])
      formData.append('driverUserId', this.rental['driver_id'])
      formData.append('departureControls', JSON.stringify(this.controls))

      const files = this.$refs.departureControlImages.getAcceptedFiles();
      files.forEach(el => {
        formData.append("images[]", el);
      })


      this.$store.dispatch('showLoader')
      rentalsService.submitDriverDepartureControl(formData).then((response) => {
        this.$store.dispatch('hideLoader')
          if(!response.status){
            this.$store.dispatch('error', {message: response.message, showSwal: true})
            return;
          }

          this.$store.dispatch('success', {message: 'Departure control submitted for driver', showSwal: true})

      })
    }
  },
  created() {

    const id = this.$route.params['id']
    this.$store.dispatch('fetchRentalDetail', id).then((rental) => {

      this.rental = rental;

      const dControls = rental['departureControls']
      if (dControls){
        this.controls = dControls
      }else{
        this.$store.dispatch('getRentalControls')
      }

      const dControlImages = rental['departureControlImages'];
      if(dControlImages){
        dControlImages.forEach(e => {
          let file = { size: e.size, name: e.name, type: e.type };
          let url = e.url;
          // this.$refs.departureControlImages.manuallyAddFile(file, url);
          this.$nextTick(()=>{
            this.$refs.departureControlImages.manuallyAddFile(file, url);
          });

        })
      }


    })

    // this.$refs.departureControlImages.$on('vdropzone-removed-file', (file) => {
    //   console.log('file removed: ', file)
    // })

  }
}
</script>

<style scoped>

</style>